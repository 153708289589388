import React from 'react'
import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/solid'

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: ' ht/mois' },
  { value: 'annually', label: 'Annually', priceSuffix: ' ht/an' },
]
const tiers = [
  {
    name: 'Offre Welcome',
    id: 'tier-freelancer',
    href: '/solution/membre/welcome/',
    label: 'Démarrer',
    price: { monthly: 'Gratuite', annually: 'Gratuite' },
    description: 'Gagner en visibilité sur internet',
    description_longue: 'Créez votre fiche membre personnalisée et présentez des informations essentielles telles que votre spécialité, les coordonnées de votre cabinet ou encore vos horaires et tarifs.',
    features: [
      'Page profil personnalisée et visible sur Google', 
      'Coordonnées en ligne', 
      'Lien vers votre site internet et réseaux sociaux', 
      'Éditable à tout moment via une interface dédiée'
    ],
    mostPopular: false,
  },
  {
    name: 'Offre Koudepouce Premium',
    id: 'tier-startup',
    href: '#formulaire_inscription',
    label: 'Démarrer',
    price: { monthly: '30 €', annually: '300 €' },
    description: 'Développer et gérer plus facilement votre activité',
    description_longue: 'Concentrez vous avant tout sur votre métier. Simplifiez la gestion de votre activité avec des fonctions de prise de RDV en ligne et paiement sécurisé des séances par CB.',
    features: [
      'Offre Welcome comprise',
      'Prise de RDV avec vos patients',
      'Paiement en ligne des séances',
      'Participation au blog Spring Medicare',
      'Évènements et ateliers entre praticiens',
    ],
    mostPopular: true,
  },
  {
    name: 'Offre Koudepouce Expert',
    id: 'tier-enterprise',
    href: '#formulaire_inscription',
    label: 'Démarrer',
    price: { monthly: '75 €', annually: '750 €' },
    description: "Bénéficier d'une solution digitale, personnalisée et encore plus complète",
    description_longue: "Rentabilisez rapidement votre investissement équivalent au prix d'une ou deux consultations !",
    features: [
      'Offre Premium comprise',
      'Participation au blog Koudepouce Santé',
      'Mini site praticien agréé Spring Medicare',
      'Et bien plus encore',
    ],
    mostPopular: false,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function SectionPrix() {
  const [frequency, setFrequency] = useState(frequencies[0])

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
            Découvrez les abonnements Spring Medicare
          </h2>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          Tarif mensuel et sans engagement
        </p>
        {/* <div className="mt-16 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
            {frequencies.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked ? 'bg-spring-bleu text-white' : 'text-gray-500',
                    'cursor-pointer rounded-full py-1 px-2.5'
                  )
                }
              >
                <span>{option.label}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div> */}
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular ? 'ring-2 ring-spring-bleu' : 'ring-1 ring-gray-200',
                'rounded-3xl p-8 xl:p-10'
              )}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id={tier.id}
                  className={classNames(
                    tier.mostPopular ? 'text-spring-bleu' : 'text-gray-900',
                    'text-lg font-semibold leading-8'
                  )}
                >
                  {tier.name}
                </h3>
                {/* {tier.mostPopular ? (
                  <p className="rounded-full bg-spring-bleu/10 py-1 px-2.5 text-xs font-semibold leading-5 text-spring-bleu">
                    Most popular
                  </p>
                ) : null} */}
              </div>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-gray-900">{tier.price[frequency.value]}</span>
                <span className="text-sm font-semibold leading-6 text-gray-600">{frequency.priceSuffix}</span>
              </p>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? 'bg-spring-bleu text-gray-900 shadow-sm hover:bg-gray-100 ring-1 ring-spring-bleu'
                    : 'text-gray-900 hover:bg-gray-100 ring-1 ring-spring-bleu',
                  'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-spring-bleu'
                )}
              >
                {tier.label}
              </a>
              <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>
              <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description_longue}</p>
              
              <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon className="h-6 w-5 flex-none text-spring-bleu" aria-hidden="true" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
