import React from 'react'
import { Disclosure } from '@headlessui/react'
import { PlusIcon, MinusIcon } from '@heroicons/react/outline'

const faqs = [
  {
    question: "Quels sont les praticiens pouvant s’inscrire sur Spring Medicare ?",
    answer:
      "Spring Medicare propose des fonctionnalités adaptées pour les médecins, thérapeutes et praticiens psychocorporels. Toutefois avant validation de votre inscription, nous validons que votre profil correspond à nos critères de sélection et exigences de qualité.",
  },
  {
    question: "Quelles sont les fonctionnalités de la formule Koudepouce Premium ?",
    answer:
      "Vous disposez d’une page profil en ligne personnalisée. Vous pouvez l'éditer à tout moment via une interface de gestion dédiée. En plus, vous disposez de fonctionnalités simplifiant vos échanges avec vos patients (prise de rendez-vous et le paiement en ligne des séances). Vous pouvez également participer aux évènements animés par Spring Medicare et/ou ses membres (conférences, ateliers, formation, mentorat…). Enfin, vous pouvez participer à l’éditorial du site Spring Medicare en diffusant votre point de vue dans le blog.",
  },
  {
    question: "Est-il utile d’être présent sur Spring Medicare si vous êtes déjà inscrit sur une autre plateforme ?",
    answer:
      "Certains praticiens ont fait le choix de s’inscrire sur Spring Medicare en complément des autres plateformes sur lesquelles ils sont déjà inscrits. D’autres en lieu et place. Doctolib a récemment supprimé plus de 5000 profils de Praticiens de Médecine Douce de son site internet. Spring Medicare propose une alternative sérieuse et fiable notamment pour la prise de rendez-vous en ligne. Le tout pour un prix dérisoire puisque l’abonnement Koudepouce Premium est à partir de 30€ HT / mois.",
  },
  {
    question: "Quelle différence entre Spring Medicare et une autre plateforme ?",
    answer:
      "Avec Spring Medicare, vous disposez  d'une solution complète pour gérer plus facilement votre activité (fiche membre en ligne, prise de rendez-vous, paiement sécurisé…). Mais Spring Medicare n’est pas une simple plateforme de prise de rendez-vous. C’est aussi une plateforme d’échange entre médecins, thérapeutes et praticiens psychocorporels, animée notamment par Philippe Lamy, Thérapeute systémicien (champ social, travail, couple…) et fondateur de Spring Medicare.",
  },
  {
    question: "Comment faire pour passer de la formule Welcome à l’abonnement Koudepouce Premium ?",
    answer:
      "Votre profil a été sélectionné et vous disposez déjà d’une page profil Welcome. Pour compléter votre profil avec des fonctions avancées (prise de RDV, paiement en ligne…), rien de plus simple. Il suffit de compléter le formulaire présent sur cette page.",
  },
  {
    question: "Comment faire pour créer un site internet by Spring Medicare ?",
    answer:
      "Si vous êtes inscrit sur Spring Medicare en formule Welcome ou Koudepouce Premium, vous pouvez solliciter nos équipes pour créer un mini site internet personnalisé. Pour être éligible, votre profil doit avoir été sélectionné par Spring Medicare et vous devez souscrire une option. Renseignement à prendre auprès de nos équipes.",
  }
]

export default function SectionFaq() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6  lg:px-8">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}